import { useEffect, useState } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Box,
    List,
    ListItem,
    ListItemText,
    Drawer,
    useScrollTrigger,
    Paper,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { logoGr } from 'assets';
import { Link, navigate } from 'gatsby';
import { globalHistory } from '@reach/router';

import { withProps } from '@blue-functor/system';

import { content, changeLanguage, language } from 'models/ui';

import { useTheme } from '@mui/material/styles';
import styles from './styles';

function Layout({
    children,
    content,
    changeLanguage,
}) {
    const [urlPrefix, setUrlPrefix] = useState('');
    const theme = useTheme();
    const handleClick = () => {
        const newUrl = window?.location?.pathname;

        navigate(newUrl?.startsWith('/el') ? newUrl.substring(3) : `/el${newUrl}`);
    };

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const newUrl = window.location.pathname;

        if (newUrl.startsWith('/el')) {
            setUrlPrefix('/el');
            changeLanguage('el');
        }
        const handleUrlChange = () => {
            const newUrl = window.location.pathname;
            if (newUrl.startsWith('/el')) {
                setUrlPrefix('/el');
                changeLanguage('el');
            } else {
                setUrlPrefix('');
                changeLanguage('en');
            }
        };

        globalHistory.listen(handleUrlChange);
    }, []);

    return (
        <div>
            <AppBar position="fixed" elevation={trigger ? 4 : 0}>
                <Toolbar>
                    <Link to="/">
                        <img
                            src={logoGr}
                            alt="logo"
                            css={styles.logo}
                        />
                    </Link>
                    <Box flex flexGrow={1} />
                    <Box css={styles.menu}>
                        <Button
                            color="inherit"
                            onClick={handleClick}
                        >
                            {urlPrefix === '/el' ? 'English' : 'Ελληνικά' }
                        </Button>
                        <Button color="inherit" component={Link} to={`${urlPrefix}/gallery`}>
                            {content.our_work}
                        </Button>
                        <Button color="inherit" component={Link} to={`${urlPrefix}/contact`}>
                            {content.get_in_touch}
                        </Button>
                    </Box>
                    <IconButton color="inherit" css={styles.mobileMenu} onClick={() => setOpen(true)}>
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}
            >
                <List>
                    <ListItem button component={Link} to="/" onClick={() => setOpen(false)}>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem button component={Link} to="/gallery" onClick={() => setOpen(false)}>
                        <ListItemText primary="Gallery" />
                    </ListItem>
                    <ListItem button component={Link} to="/contact" onClick={() => setOpen(false)}>
                        <ListItemText primary="Get in touch" />
                    </ListItem>
                </List>
            </Drawer>
            <div css={styles.spacer} />
            {children}
            <Paper css={styles.footer}>
                <Typography css={styles.logo} variant="h6" component={Link} to="/">
                    Tsiplakidis
                </Typography>
                <Typography>
                    Copyright ©
                    {' '}
                    {new Date().getFullYear()}
                    {' '}
                    Tsiplakidis
                </Typography>
            </Paper>
        </div>
    );
}

export default withProps({ content, language }, { changeLanguage })(Layout);
