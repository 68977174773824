exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-business-spaces-index-js": () => import("./../../../src/pages/business-spaces/index.js" /* webpackChunkName: "component---src-pages-business-spaces-index-js" */),
  "component---src-pages-business-spaces-storyblok-entry-slug-js": () => import("./../../../src/pages/business-spaces/{storyblokEntry.slug}.js" /* webpackChunkName: "component---src-pages-business-spaces-storyblok-entry-slug-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-el-business-spaces-index-js": () => import("./../../../src/pages/el/business-spaces/index.js" /* webpackChunkName: "component---src-pages-el-business-spaces-index-js" */),
  "component---src-pages-el-business-spaces-storyblok-entry-slug-js": () => import("./../../../src/pages/el/business-spaces/{storyblokEntry.slug}.js" /* webpackChunkName: "component---src-pages-el-business-spaces-storyblok-entry-slug-js" */),
  "component---src-pages-el-contact-js": () => import("./../../../src/pages/el/contact.js" /* webpackChunkName: "component---src-pages-el-contact-js" */),
  "component---src-pages-el-frames-index-js": () => import("./../../../src/pages/el/frames/index.js" /* webpackChunkName: "component---src-pages-el-frames-index-js" */),
  "component---src-pages-el-frames-storyblok-entry-slug-js": () => import("./../../../src/pages/el/frames/{storyblokEntry.slug}.js" /* webpackChunkName: "component---src-pages-el-frames-storyblok-entry-slug-js" */),
  "component---src-pages-el-gallery-index-js": () => import("./../../../src/pages/el/gallery/index.js" /* webpackChunkName: "component---src-pages-el-gallery-index-js" */),
  "component---src-pages-el-gallery-storyblok-entry-slug-js": () => import("./../../../src/pages/el/gallery/{storyblokEntry.slug}.js" /* webpackChunkName: "component---src-pages-el-gallery-storyblok-entry-slug-js" */),
  "component---src-pages-el-index-js": () => import("./../../../src/pages/el/index.js" /* webpackChunkName: "component---src-pages-el-index-js" */),
  "component---src-pages-frames-index-js": () => import("./../../../src/pages/frames/index.js" /* webpackChunkName: "component---src-pages-frames-index-js" */),
  "component---src-pages-frames-storyblok-entry-slug-js": () => import("./../../../src/pages/frames/{storyblokEntry.slug}.js" /* webpackChunkName: "component---src-pages-frames-storyblok-entry-slug-js" */),
  "component---src-pages-gallery-index-js": () => import("./../../../src/pages/gallery/index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-gallery-storyblok-entry-slug-js": () => import("./../../../src/pages/gallery/{storyblokEntry.slug}.js" /* webpackChunkName: "component---src-pages-gallery-storyblok-entry-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

