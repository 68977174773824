import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#4e525d',
            text: '#1b243f',
            contrastText: '#FFF6F9',
            background: '#a3a3a3',
        },
        secondary: {
            main: '#1b243f',
            contrastText: '#FFF6F9',
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                rounded: {
                    boxShadow: '30px 54px 67px rgba(209, 217, 230, 0.67), 30px 24px 34px rgba(209, 217, 230, 0.34), 30px 28px 38px rgba(209, 217, 230, 0.4), 30px 35px 45px rgba(209, 217, 230, 0.48), -20px -63px 65px rgba(255, 255, 255, 0.75), -20px -36.8341px 42.6719px rgba(255, 255, 255, 0.539141), -20px -31.3638px 35.026px rgba(255, 255, 255, 0.44708), -20px -28.4185px 30.1667px rgba(255, 255, 255, 0.375)',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#ffffff',
                        },
                        '&:hover fieldset': {
                            borderColor: '#ffffff',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#ffffff',
                        },
                    },
                },
            },
        },
    },
});

export default lightTheme;
