import CssBaseline from '@mui/material/CssBaseline';
import Providers from './src/providers';
import { lightTheme } from './src/themes';
import { Layout } from './src/components';

export const wrapRootElement = ({ element }) => (
    <Providers
        options={{ appName: '' }}
        theme={lightTheme}
        maxNotifications={3}
    >
        <CssBaseline />
        <Layout>
            {element}
        </Layout>
    </Providers>
);
