import { createSelector } from 'reselect';

export const snackbar = ({ ui }) => ui.snackbar;
export const emailSendLoading = ({ ui }) => ui.emailSendLoading;
export const emailSendSuccess = ({ ui }) => ui.emailSendSuccess;
export const emailSendError = ({ ui }) => ui.emailSendError;

export const language = ({ ui }) => ui.language;
export const translations = ({ ui }) => ui.translations;

export const content = createSelector(
    language,
    translations,
    (language, translations) => translations[language],
);
