import { combineEpics } from 'redux-observable';
import { timer } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ofType } from '@blue-functor/system/operators';
import { sendEmail } from './actions';

const sendEmailEpic = (actions$) => actions$.pipe(
    ofType(sendEmail),
    mergeMap(() => timer(2000)),
    map(() => sendEmail.succeeded()),
);

export default combineEpics(
    sendEmailEpic,
);
