import { Helmet } from 'react-helmet';

function SEO({
    title = 'Tsiplakidis',
    description = 'Original Wood Creations',
    subTitle,
}) {
    return (
        <Helmet>
            <title>{subTitle ? `${title} | ${subTitle}` : title}</title>
            <meta name="description" content={description} />
        </Helmet>
    );
}

export default SEO;
