import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';
import { changeLanguage } from './actions';

const translations = {
    en: {
        tsiplakidis: 'Tsiplakidis',
        wood_works: 'Wood Words',
        our_work: 'Our Work',
        get_in_touch: 'Get in touch',
        frames: 'Frames',
        business_spaces: 'Business Spaces',
        isEnglish: true,
    },
    el: {
        tsiplakidis: 'Τσιπλακίδης',
        wood_works: 'Ξυλουργική',
        our_work: 'Η δουλειά μας',
        get_in_touch: 'Επικοινωνήστε μαζί μας',
        frames: 'Κουφώματα',
        business_spaces: 'Επαγγελματικοί Χώροι',
        isEnglish: false,
    },
};

const initState = {
    snackbar: [],
    emailSendLoading: false,
    emailSendSuccess: false,
    emailSendError: false,
    language: 'en',
    translations,
};

const reducer = createReducer(initState, (builder) => builder
    .addCase(actions.openSnackbar, (state, { payload }) => {
        state.snackbar.pop();
        state.snackbar.push({
            isOpen: true,
            type: payload.type,
            message: payload.message,
        });
    })
    .addCase(actions.sendEmail, (state) => {
        state.emailSendLoading = true;
    })
    .addCase(actions.sendEmail.succeeded, (state) => {
        state.emailSendLoading = false;
        state.emailSendSuccess = true;
    })
    .addCase(actions.sendEmail.failed, (state) => {
        state.emailSendLoading = false;
        state.emailSendError = true;
    })
    .addCase(actions.changeLanguage, (state, { payload }) => {
        state.language = payload;
    }));

export default reducer;
