import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

function FormTextField({
    control,
    name,
    label,
    controllerProps,
    inputProps,
    required = false,
}) {
    return (
        <Controller
            name={name}
            control={control}
            rules={{
                required,
            }}
            render={({ field, formState }) => (
                <TextField
                    {...field}
                    label={label}
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    required={required}
                    error={formState.errors?.[name]?.message}
                    helperText={formState.errors?.[name]?.message}
                    sx={{
                        '& label.Mui-focused': {
                            color: 'white',
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: 'white',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'white',
                            },
                            '&:hover fieldset': {
                                borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'white',
                            },
                        },
                    }}
                    {...inputProps}
                />
            )}
            {...controllerProps}
        />
    );
}

export default FormTextField;
