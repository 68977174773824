const styles = {
    logo: (theme) => ({
        color: theme.palette.primary.contrastText,
        textDecoration: 'none',
        flexGrow: 1,
        maxWidth: '200px',
    }),
    spacer: (theme) => ({
        height: '64px',
        backgroundColor: theme.palette.primary.main,
    }),
    menu: () => ({
        display: 'flex',
        '@media (max-width: 1000px)': {
            display: 'none',
        },
    }),
    mobileMenu: () => ({
        display: 'none',
        '@media (max-width: 1000px)': {
            display: 'flex',
        },
    }),
    footer: (theme) => ({
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: '16px',
        textAlign: 'center',
        margin: '3rem auto',
        width: '80%',
    }),
    link: (theme) => ({
        color: theme.palette.primary.contrastText,
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.primary.dark,
        },
    }),
};

export default styles;
